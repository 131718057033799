export const RoleType = Object.freeze({
  SUPER_ADMIN: "SUPER_ADMIN",
  CM: "CM",
  ADVISOR: "ADVISOR",
  NSM: "NSM",
  NMM: "NMM",
  SA: "SA",
  MT: "MT",
  RM: "RM",
  AM: "AM",
  TSS: "TSS",
  SP: "SP"
});

export const RegHolder = Object.freeze({
  PT_UPLI: {
    value: "PT_UPLI",
    label: "PT UPL Indonesia"
  },
  PT_CAM: {
    value: "PT_CAM",
    label: "PT Catur Agordaya Mandiri"
  }
});

export const RetailerType = Object.freeze({
  R1: "R1",
  R2: "R2"
});

export const SalesTargetType = Object.freeze({
  ANNUAL: "ANNUAL",
  MONTHLY: "MONTHLY"
});

export const TypeKegiatan = Object.freeze({
  VD: {
    value: "VD",
    label: "Visit Distributor"
  },
  VR1: {
    value: "VR1",
    label: "Visit Retailer 1"
  },
  VR2: {
    value: "VR2",
    label: "Visit Retailer 2"
  },
  VF: {
    value: "VF",
    label: "Visit Farmer"
  },
  VK: {
    value: "VK",
    label: "Visit Kebun"
  },
  MA: {
    value: "MA",
    label: "Marketing Activity"
  },
  ADM: {
    value: "ADM",
    label: "Administration"
  }
});

export const JenisKegiatan = Object.freeze({
  DP: {
    value: "DP",
    label: "Demo Plot"
  },
  FFD: {
    value: "FFD",
    label: "Farmer Field Day"
  },
  SMF: {
    value: "SMF",
    label: "Small FM"
  },
  SAF: {
    value: "SAF",
    label: "Safari FM"
  },
  ODP: {
    value: "ODP",
    label: "Safari FM"
  },
  BF: {
    value: "BF",
    label: "Big FM"
  },
  KF: {
    value: "KF",
    label: "Key FM"
  },
  SE: {
    value: "SE",
    label: "Sponsorship Event"
  },
  R1M: {
    value: "R1M",
    label: "R1 Meeting"
  },
  R2M: {
    value: "R2M",
    label: "R2 Meeting"
  }
});

export const KegiatanSP = Object.freeze({
  DEMOPLOT: {
    value: "DEMOPLOT",
    label: "Demoplot"
  },
  FFD: {
    value: "FFD",
    label: "FFD"
  },
  SFM: {
    value: "SFM",
    label: "Small Farmer Meeting"
  },
  SPRAY: {
    value: "SPRAY",
    label: "Spray Massal"
  },
  ODP: {
    value: "ODP",
    label: "One Day Promotion"
  },
  BFM: {
    value: "BFM",
    label: "Big Farmer Meeting"
  },
  KFM: {
    value: "KFM",
    label: "Key Farmer Meeting"
  },
  SCP: {
    value: "SCP",
    label: "Sales Call Petani"
  },
  SCK: {
    value: "SCK",
    label: "Sales Call Kios"
  }
});

export const SortType = Object.freeze({
  ASC: "ASC",
  DESC: "DESC"
});

export const statusProposalType = Object.freeze({
  CHECKED: "CHECKED",
  APPROVED: "APPROVED",
  PROCESS: "PROCESS",
  DELIVERED: "DELIVERED",
  RECEIVED: "RECEIVED",
  DECLINED: "DECLINED"
});

export const statusAccountRequest = Object.freeze({
  PENDING: "PENDING",
  APPROVE: "APPROVE",
  DECLINE: "DECLINE"
});

export const tujuanPromosi = Object.freeze({
  SAFM: "SAFM",
  SAOD: "SAOD",
  SAFF: "SAFF",
  DB: "DB",
  EB: "EB",
  KB: "KB",
  PB: "PB"
});

export const inventoryType = Object.freeze({
  SAMPLE: "SAMPLE",
  MATPRO: "MATPRO"
});

export const filterDateType = Object.freeze({
  ALL: "ALL",
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  THIS_WEEK: "THIS_WEEK",
  THIS_MONTH: "THIS_MONTH",
  CUSTOM_DATE: "CUSTOM_DATE",
  LAST_SEVEN_DAYS: "LAST_SEVEN_DAYS",
  LAST_THIRTY_DAYS: "LAST_THIRTY_DAYS",
  LAST_NINETY_DAYS: "LAST_NINETY_DAYS"
});
