<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("dailyreport.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-2 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("dailyreport.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right"> </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('dailyreport.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-filter"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-filter"
                triggers="focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab title="Tanggal" active>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-today"
                        :value="filterDateTypes[0]"
                        @change="resetFilter1"
                        checked
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-today">
                        <div class="col">
                          <div class="row ssp-14">Today</div>
                          <div class="row">{{ today | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-yesterday"
                        :value="filterDateTypes[1]"
                        @change="resetFilter1"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-yesterday">
                        <div class="col">
                          <div class="row ssp-14">Yesterday</div>
                          <div class="row">{{ yesterday | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-week"
                        :value="filterDateTypes[2]"
                        @change="setFilterSelectedWeek"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-week">
                        <div class="col">
                          <div class="row ssp-14">This week</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayWeek)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayWeek | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-month"
                        :value="filterDateTypes[3]"
                        @change="setFilterSelectedMonth"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-month">
                        <div class="col">
                          <div class="row ssp-14">This month</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayMonth)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayMonth | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-custom"
                        :value="filterDateTypes[4]"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label ssp-14" for="radio-custom">
                        Custom Range
                      </label>
                    </div>
                    <b-row
                      v-if="filterDateType == filterDateTypes[4]"
                      class="mb-2"
                    >
                      <b-col cols="6" class="pr-1">
                        <span>FROM</span>
                        <input
                          type="date"
                          class="form-control float-right"
                          v-model="filterSelected1"
                        />
                      </b-col>
                      <b-col cols="6" class="pl-1">
                        <span>TO</span>
                        <input
                          type="date"
                          class="form-control"
                          v-model="filterSelected2"
                        />
                      </b-col>
                    </b-row>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 ssp-14"
                      :class="{ disabled: filterDateType === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="User">
                    <div class="row">
                      <select
                        class="form-control search-input-1 col-5 ml-3 mr-1"
                        v-model="filterRole"
                      >
                        <option
                          v-for="role in roleOptions"
                          :key="role.id"
                          :value="role"
                        >
                          {{ role }}
                        </option>
                      </select>
                      <input
                        type="text"
                        class="form-control search-input-1 col-5 ml-2 mr-1"
                        v-model="searchUser"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="user in dataUser"
                        :key="user.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :id="user.id"
                          :value="user.id"
                          v-model="filterRoleApply"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="user.id"
                        >
                          {{ user.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRoleApply == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Region">
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="rg in dataRegion"
                        :key="rg.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="parseInt(rg.id)"
                          :id="rg.id"
                          v-model="filterRegion"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="rg.id"
                        >
                          {{ rg.region }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRegion.length === 0 }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Kegiatan">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="kegiatan in typeKegiatanOptions"
                        :key="kegiatan.id"
                      >
                        <input
                          :id="kegiatan.value"
                          class="form-radio-item"
                          type="radio"
                          name="kegiatan-label"
                          :value="kegiatan.value"
                          v-model="filterKegiatan"
                        />
                        <label
                          class="form-radio-label ssp-14-400"
                          :for="kegiatan.value"
                        >
                          {{ kegiatan.label }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterKegiatan === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('dailyreport.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="6" />
              </b-tbody>
              <b-tbody v-else-if="this.dataLaporan.length !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="laporan in dataLaporan"
                  :key="laporan.id"
                >
                  <b-td
                    ><input
                      type="checkbox"
                      :value="laporan.id"
                      v-model="lapIDs"
                      @click="select"
                  /></b-td>
                  <b-td>
                    <span>
                      {{ laporan.users.name }}
                    </span>
                    <span class="badge badge-gray">
                      {{ laporan.role }}
                    </span>
                  </b-td>
                  <b-td>{{ laporan.tanggal | formatDate2 }}</b-td>
                  <b-td>{{ laporan.createdAt | formatDate2 }}</b-td>
                  <b-td>
                    <span class="badge badge-blue">{{
                      convertKegiatan(laporan.kegiatan)
                    }}</span>
                  </b-td>
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(laporan)"
                      ></i>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="10">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-laporan" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form>
          <div class="form-group">
            <label for="tanggal-laporan" class="ssp-16 text-black">
              {{ $t("dailyreport.label.date") }}
            </label>
            <datepicker
              id="tanggal-laporan"
              v-if="isDisabled"
              input-class="form-control"
              v-model="formTambah.tanggal"
              :disabled="true"
            />
          </div>

          <section v-if="fieldKeg === 'VD'">
            <div class="form-group">
              <label for="distributor1" class="ssp-16 text-black">
                {{ $t("dailyreport.label.vd") }} 1
              </label>
              <input
                v-if="isDetail"
                id="distributor1"
                type="text"
                class="form-control"
                v-model="$v.formTambah.distributor1.$model"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hd2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_distributor1.$error }"
                id="hd2"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_distributor1.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
            <div v-if="expandDist" class="form-group mt-4">
              <label for="distributor2" class="ssp-16 text-black">
                {{ $t("dailyreport.label.vd") }} 2
              </label>
              <input
                v-if="isDetail"
                id="distributor2"
                type="text"
                class="form-control"
                v-model="$v.formTambah.distributor2.$model"
                :disabled="true"
              />
            </div>
            <div v-if="expandDist" class="form-group">
              <label for="hd2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_distributor2.$error }"
                id="hd2"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_distributor2.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VR1'">
            <div class="form-group">
              <label for="retailer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 1</label
              >
              <input
                v-if="isDetail"
                id="retailer1"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer1.$model"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer1.$error }"
                id="hr1"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer1.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
            <div v-if="expandRet1" class="form-group mt-4">
              <label for="retailer2" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 2</label
              >
              <input
                v-if="isDetail"
                id="retailer2"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer2.$model"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet1" class="form-group mb-2">
              <label for="hr2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer2.$error }"
                id="hr2"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer2.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
            <div v-if="expandRet2" class="form-group mt-4">
              <label for="retailer3" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 3</label
              >
              <input
                v-if="isDetail"
                id="retailer3"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer3.$model"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet2" class="form-group mb-2">
              <label for="hr3" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer3.$error }"
                id="hr3"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer3.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VR2'">
            <div class="form-group">
              <label for="retailer21" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 1</label
              >
              <input
                v-if="isDetail"
                id="retailer21"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer1.$model"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer1.$error }"
                id="hr1"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer1.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
            <div v-if="expandRet1" class="form-group mt-4">
              <label for="retailer22" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 2</label
              >
              <input
                v-if="isDetail"
                id="retailer22"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer2.$model"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet1" class="form-group">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer2.$error }"
                id="hr1"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer2.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
            <div v-if="expandRet2" class="form-group mt-4">
              <label for="retailer3" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 3</label
              >
              <input
                v-if="isDetail"
                id="retailer3"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer3.$model"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet2" class="form-group mb-2">
              <label for="hr3" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer3.$error }"
                id="hr3"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer3.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VF'">
            <div class="form-group">
              <label for="farmer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.farmer") }} 1</label
              >
              <input
                v-if="isDetail"
                id="farmer1"
                type="text"
                class="form-control"
                v-model="$v.formTambah.farmer1.$model"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hf1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_farmer1.$error }"
                id="hf1"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_farmer1.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
            <div v-if="expandFarm" class="form-group mt-4">
              <label for="farmer2" class="ssp-16 text-black">
                {{ $t("dailyreport.label.farmer") }} 2
              </label>
              <input
                v-if="isDetail"
                id="farmer2"
                type="text"
                class="form-control"
                v-model="$v.formTambah.farmer2.$model"
                :disabled="true"
              />
            </div>
            <div v-if="expandFarm" class="form-group mb-2">
              <label for="hf2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_farmer2.$error }"
                id="hf2"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_farmer2.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VK'">
            <div class="form-group">
              <label for="nama-kebun" class="ssp-16 text-black">{{
                $t("dailyreport.label.vk")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-kebun"
                placeholder="Masukkan nama kebun"
                :class="{ 'invalid-form': $v.formTambah.kebun.$error }"
                v-model="$v.formTambah.kebun.$model"
                :disabled="isDisabled"
              />
            </div>
            <label for="hk" class="ssp-16 text-black">{{
              $t("dailyreport.label.result")
            }}</label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.h_kebun.$error }"
              id="hk"
              cols="30"
              rows="5"
              :placeholder="$t('dailyreport.placeholder.result')"
              v-model="$v.formTambah.h_kebun.$model"
              :disabled="isDisabled"
            ></textarea>
          </section>

          <section v-if="fieldKeg === 'MA'">
            <div v-if="fieldKeg === 'MA'" class="form-group">
              <label for="nama-kegmr" class="ssp-16 text-black">{{
                $t("dailyreport.label.ma")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-kegmr"
                placeholder="Masukkan nama kegiatan Marketing"
                :class="{ 'invalid-form': $v.formTambah.marketing.$error }"
                v-model="$v.formTambah.marketing.$model"
                :disabled="isDisabled"
              />
            </div>
            <label for="hma" class="ssp-16 text-black">{{
              $t("dailyreport.label.result")
            }}</label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.h_marketing.$error }"
              id="hma"
              cols="30"
              rows="5"
              :placeholder="$t('dailyreport.placeholder.result')"
              v-model="$v.formTambah.h_marketing.$model"
              :disabled="isDisabled"
            ></textarea>
          </section>

          <section v-if="fieldKeg === 'ADM'">
            <div v-if="fieldKeg === 'ADM'" class="form-group">
              <label for="nama-adm" class="ssp-16 text-black">{{
                $t("dailyreport.label.adm")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-adm"
                placeholder="Masukkan Administrasi"
                :class="{ 'invalid-form': $v.formTambah.administ.$error }"
                v-model="$v.formTambah.administ.$model"
                :disabled="isDisabled"
              />
            </div>
            <div v-if="fieldKeg === 'ADM'" class="form-group">
              <label for="h-adm" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_administ.$error }"
                id="h-adm"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_administ.$model"
                :disabled="isDisabled"
              ></textarea>
            </div>
          </section>

          <div class="form-group mt-3">
            <label for="doc" class="ssp-16 text-black">
              {{ $t("dailyreport.label.doc") }}
            </label>
            <div class="row" v-if="isDetail">
              <div class="col-md-10 offset-1 mt-3">
                <img
                  id="doc"
                  class="d-block ml-auto mr-auto"
                  v-if="formTambah.dokumentasi != null"
                  :src="url + formTambah.dokumentasi"
                  :alt="formTambah.dokumentasi"
                />
                <div v-else class="mx-auto text-center">
                  {{ $t("feedback.dnot_avb") }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { Card } from "@/components/index";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required, requiredIf } from "vuelidate/lib/validators";
import { Q_LIST_DAILYREPORT } from "@/graphql/cm";
import { Q_LIST_REGION, Q_LIST_USERSEARCH } from "@/graphql/queries";
import { TypeKegiatan, RoleType, filterDateType } from "@/graphql/enum.type.js";
import JwtService from "@/core/services/jwt.service";
import Axios from "axios";

export default {
  components: {
    Card,
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      dataLaporan: [],
      dataRegion: [],
      url: JwtService.getURL(),
      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      dataUser: [],
      filterRole: null,
      filterRegion: [],
      filterRoleApply: [],
      searchUser: "",

      roleOptions: [RoleType.RM, RoleType.AM, RoleType.TSS, RoleType.SP],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterKegiatan: null,
      show_filter: false,
      customFilter: false,
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      disableDates: {
        to: new Date(new Date() - 172800000),
        from: new Date(new Date() + 86400000)
      },

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,

      lapIDs: [],
      selected: [],
      allSelected: false,

      dataProduk: [],
      dataR1: [],
      dataR2: [],
      dataDistributor: [],
      dataFarmer: [],

      typeKegiatanOptions: [
        TypeKegiatan.VD,
        TypeKegiatan.VR1,
        TypeKegiatan.VR2,
        TypeKegiatan.VF,
        TypeKegiatan.VK,
        TypeKegiatan.MA,
        TypeKegiatan.ADM
      ],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],
      modalTitle: "",
      isEdit: false,
      isDisabled: false,
      isDetail: false,

      // invoiceFile: [], // Store our uploaded files
      retailerSelected: "",

      fieldKeg: null,
      dokumentasiFile: null,
      dokumentasiName: null,
      dokumentasiURL: null,
      expandDist: false,
      expandRet1: false,
      expandRet2: false,
      expandFarm: false,
      formTambah: {
        id: "",
        tanggal: null,
        distributor1: null,
        h_distributor1: null,
        distributor2: null,
        h_distributor2: null,
        retailer1: null,
        h_retailer1: null,
        retailer2: null,
        h_retailer2: null,
        retailer3: null,
        h_retailer3: null,
        farmer1: null,
        h_farmer1: null,
        farmer2: null,
        h_farmer2: null,
        kebun: null,
        h_kebun: null,
        marketing: null,
        h_marketing: null,
        administ: null,
        h_administ: null,
        dokumentasi: null
      }
    };
  },
  validations: {
    fieldKeg: { required },
    formTambah: {
      tanggal: { required },
      distributor1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD";
        })
      },
      h_distributor1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD";
        })
      },
      distributor2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD" && this.expandDist;
        })
      },
      h_distributor2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD" && this.expandDist;
        })
      },
      retailer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VR1" || this.fieldKeg === "VR2";
        })
      },
      h_retailer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VR1" || this.fieldKeg === "VR2";
        })
      },
      retailer2: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet1
          );
        })
      },
      h_retailer2: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet1
          );
        })
      },
      retailer3: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet2
          );
        })
      },
      h_retailer3: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet2
          );
        })
      },
      farmer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF";
        })
      },
      h_farmer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF";
        })
      },
      farmer2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF" && this.expandFarm;
        })
      },
      h_farmer2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF" && this.expandFarm;
        })
      },
      kebun: {
        required: requiredIf(function() {
          return this.fieldKeg === "VK";
        })
      },
      h_kebun: {
        required: requiredIf(function() {
          return this.fieldKeg === "VK";
        })
      },
      marketing: {
        required: requiredIf(function() {
          return this.fieldKeg === "MA";
        })
      },
      h_marketing: {
        required: requiredIf(function() {
          return this.fieldKeg === "MA";
        })
      },
      administ: {
        required: requiredIf(function() {
          return this.fieldKeg === "ADM";
        })
      },
      h_administ: {
        required: requiredIf(function() {
          return this.fieldKeg === "ADM";
        })
      }
    },
    dokumentasiFile: {
      required: requiredIf(function() {
        return !this.isEdit;
      })
    }
  },
  apollo: {
    listDailyReport: {
      query: () => Q_LIST_DAILYREPORT,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          user_id: this.filterRoleApply,
          region_id:
            this.filterRegion.length === 0
              ? this.getRegionID()
              : this.filterRegion,
          type: this.filterKegiatan,
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataLaporan = data.listDailyReport.dailys;
        this.totalPages = data.listDailyReport.totalPage;
        this.totalData = data.listDailyReport.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchUser,
          role: this.filterRole,
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      // update: data => data.listUser,
      result({ data }) {
        this.dataUser = data.listUser.users;
      }
    },
    listRegion: {
      query: () => Q_LIST_REGION,
      result({ data }) {
        const r = this.getRegionID();
        if (r.length > 0) {
          this.dataRegion = data.listRegion.regions.filter(reg => {
            if (r.includes(parseInt(reg.id))) {
              return reg;
            }
          });
        } else {
          this.dataRegion = data.listRegion.regions;
        }
      }
    }
  },
  computed: {},
  methods: {
    resetKeg() {
      this.expandDist = false;
      this.expandRet1 = false;
      this.expandRet2 = false;
      this.expandFarm = false;
    },
    getDay(value) {
      return moment(value).format("DD");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
      this.setPage();
      this.customFilter = false;
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
      this.setPage();
      this.customFilter = false;
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.customFilter = false;
      this.filterKegiatan = null;
      this.filterRoleApply = [];
      this.filterRegion = [];
      this.searchUser = "";
    },
    resetFilter1() {
      this.filterSelected2 = "";
      this.setPage();
      this.customFilter = false;
    },

    selectAll() {
      this.lapIDs = [];
      if (!this.allSelected) {
        for (let i in this.dataLaporan) {
          this.lapIDs.push(this.dataLaporan[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },

    onDetail(data) {
      this.modalTitle = this.$t("dailyreport.detail");
      this.fieldKeg = data.kegiatan;
      this.isDetail = true;
      this.isEdit = false;
      this.isDisabled = true;
      this.resetForm();
      this.resetKeg();

      this.formTambah.tanggal = this.formatDate(data.tanggal);
      if (data.d1 !== null) {
        this.formTambah.distributor1 = `${data.distributor1.name} (${data.distributor1.owner})`;
        this.formTambah.h_distributor1 = data.hd1;
      }
      if (data.d2 !== null) {
        this.expandDist = true;
        this.formTambah.distributor2 = `${data.distributor2.name} (${data.distributor2.owner})`;
        this.formTambah.h_distributor2 = data.hd2;
      }
      if (data.r1 !== null) {
        this.formTambah.retailer1 = `${data.retailer1.name} (${data.retailer1.owner})`;
        this.formTambah.h_retailer1 = data.hr1;
      }
      if (data.r2 !== null) {
        this.expandRet1 = true;
        this.formTambah.retailer2 = `${data.retailer2.name} (${data.retailer2.owner})`;
        this.formTambah.h_retailer2 = data.hr2;
      }
      if (data.r3 !== null) {
        this.expandRet2 = true;
        this.formTambah.retailer3 = `${data.retailer3.name} (${data.retailer3.owner})`;
        this.formTambah.h_retailer3 = data.hr3;
      }
      if (data.p1 !== null) {
        this.formTambah.farmer1 = data.petani1.name;
        this.formTambah.h_farmer1 = data.hp1;
      }
      if (data.p2 !== null) {
        this.expandFarm = true;
        this.formTambah.farmer2 = data.petani2.name;
        this.formTambah.h_farmer2 = data.hp2;
      }
      this.formTambah.kebun = data.nama_kebun;
      this.formTambah.h_kebun = data.hasil_kebun;
      this.formTambah.marketing = data.nama_marketing;
      this.formTambah.h_marketing = data.hasil_marketing;
      this.formTambah.administ = data.nama_administrasi;
      this.formTambah.h_administ = data.hasil_administrasi;
      this.formTambah.dokumentasi = data.dokumentasi;

      this.$bvModal.show("modal-laporan");
    },
    resetForm() {
      this.formTambah.tanggal = null;
      this.formTambah.distributor1 = null;
      this.formTambah.h_distributor1 = null;
      this.formTambah.distributor2 = null;
      this.formTambah.h_distributor2 = null;
      this.formTambah.retailer1 = null;
      this.formTambah.h_retailer1 = null;
      this.formTambah.retailer2 = null;
      this.formTambah.h_retailer2 = null;
      this.formTambah.retailer3 = null;
      this.formTambah.h_retailer3 = null;
      this.formTambah.farmer1 = null;
      this.formTambah.h_farmer1 = null;
      this.formTambah.farmer2 = null;
      this.formTambah.h_farmer2 = null;
      this.formTambah.kebun = null;
      this.formTambah.h_kebun = null;
      this.formTambah.marketing = null;
      this.formTambah.h_marketing = null;
      this.formTambah.administ = null;
      this.formTambah.h_administ = null;
      this.formTambah.dokumentasi = null;
      this.$v.$reset();
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    convertKegiatan(data) {
      let Value;
      switch (data) {
        case "VD":
          Value = "Visit Distributor";
          break;
        case "VR1":
          Value = "Visit Retailer 1";
          break;
        case "VR2":
          Value = "Visit Retailer 2";
          break;
        case "VF":
          Value = "Visit Farmer";
          break;
        case "VK":
          Value = "Visit Kebun";
          break;
        case "MA":
          Value = "Marketing Activity";
          break;
        case "ADM":
          Value = "Administration";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + `/daily-report/excel`,
        {
          dateType: this.handleCustomDateExport(this.filterDateType),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          user: this.filterRoleApply,
          region: this.filterRegion,
          kegiatan: this.convertKegDaily(this.filterKegiatan)
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `dailyreport-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
          this.isLoading = false;
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
    // async handleExport() {
    //   try {
    //     const result = await this.$apollo.query({
    //       query: Q_LIST_DAILYREPORT,
    //       variables: {
    //         page: this.currPage,
    //         limit: this.totalData,
    //         keyword: this.filterInput,
    //         user_id: this.filterRoleApply,
    //         region_id:
    //           this.filterRegion.length === 0
    //             ? this.getRegionID()
    //             : this.filterRegion,
    //         type: this.filterKegiatan,
    //         start_date: this.filterSelected1,
    //         end_date: this.filterSelected2
    //       }
    //     });
    //     const list = result.data.listDailyReport.dailys;
    //     import("@/plugins/export-excel").then(excel => {
    //       const tHeader = [
    //         "User",
    //         "Role",
    //         "Region",
    //         "Tanggal",
    //         "Kegiatan",
    //         "Distributor 1",
    //         "Hasil",
    //         "Distributor 2",
    //         "Hasil",
    //         "Retailer 1",
    //         "Hasil",
    //         "Retailer 2",
    //         "Hasil",
    //         "Retailer 3",
    //         "Hasil",
    //         "Petani 1",
    //         "Hasil",
    //         "Petani 2",
    //         "Hasil",
    //         "Nama Kebun",
    //         "Hasil",
    //         "Nama Marketing",
    //         "Hasil",
    //         "Nama Administrasi",
    //         "Hasil",
    //         "Lng",
    //         "Lat"
    //       ];
    //       const filterVal = [
    //         "user",
    //         "role",
    //         "region",
    //         "tanggal",
    //         "kegiatan",
    //         "dis1",
    //         "hd1",
    //         "dis2",
    //         "hd2",
    //         "ret1",
    //         "hr1",
    //         "ret2",
    //         "hr2",
    //         "ret3",
    //         "hr3",
    //         "pet1",
    //         "hp1",
    //         "pet2",
    //         "hp2",
    //         "nama_kebun",
    //         "hasil_kebun",
    //         "nama_marketing",
    //         "hasil_marketing",
    //         "nama_administrasi",
    //         "hasil_administrasi",
    //         "longitude",
    //         "latitude"
    //       ];
    //       setTimeout(() => {
    //         list.map(a => {
    //           if (a.users) {
    //             a.user = a.users.name;
    //           }
    //           if (a.regions) {
    //             a.region = a.regions.region;
    //           }
    //           if (a.distributor1) {
    //             a.dis1 = a.distributor1.name;
    //           }
    //           if (a.distributor2) {
    //             a.dis2 = a.distributor2.name;
    //           }
    //           if (a.retailer1) {
    //             a.ret1 = a.retailer1.name;
    //           }
    //           if (a.retailer2) {
    //             a.ret2 = a.retailer2.name;
    //           }
    //           if (a.retailer3) {
    //             a.ret3 = a.retailer3.name;
    //           }
    //           if (a.petani1) {
    //             a.pet1 = a.petani1.name;
    //           }
    //           if (a.petani2) {
    //             a.pet2 = a.petani2.name;
    //           }
    //           return a;
    //         });
    //         const data = this.formatJson(filterVal, list);
    //         excel.export_json_to_excel({
    //           header: tHeader,
    //           data,
    //           filename: this.$route.name,
    //           autoWidth: true,
    //           bookType: "xlsx"
    //         });
    //       }, 1500);
    //       this.limit = 10;
    //     });
    //   } catch (e) {
    //     this.errorAlert(e);
    //   }
    // }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  },
  created() {
    this.filterRole = this.roleOptions[0];
  }
};
</script>

<style>
.datepicker {
  background-color: #fff !important;
}

.daily-1 {
  width: 12.5%;
}

.daily-2 {
  width: 12.5%;
}

.daily-3 {
  width: 60%;
}
</style>
